var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-box"},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"320","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""}},on),[_c('v-icon',[_vm._v("mdi-tune-variant")])],1)]}}])},[_c('v-card',[_c('v-container',[_c('BaseFilter',{attrs:{"prepend-inner-icon":"mdi-file-table-box-multiple-outline","clearable":"","single-line":"","label":"Segmento","item-text":"descricao","item-value":"id_band","service":"sistemaService.tipoBandeiras","filters":{ id_band: '2,4' },"isCompany":true},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_band),callback:function ($$v) {_vm.$set(_vm.filters, "id_band", $$v)},expression:"filters.id_band"}}),_c('BaseFilter',{attrs:{"prepend-inner-icon":"mdi-domain","clearable":"","single-line":"","label":"Empresa","item-text":"apelido","item-value":"id_empresa","service":"sistemaService.empresa","filters":{
            id_band: _vm.filters.id_band,
            calcula_comissao: 'S'
          },"isCompany":true},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_empresa),callback:function ($$v) {_vm.$set(_vm.filters, "id_empresa", $$v)},expression:"filters.id_empresa"}}),_c('DatePickerMonth',{attrs:{"chips":"","currentDate":true},on:{"date":function($event){return _vm.assignDateFilter($event)}}}),_c('BaseFilter',{attrs:{"prepend-inner-icon":"mdi-sale-outline","clearable":"","single-line":"","label":"Campanha","item-text":"nome_campanha","item-value":"id_campanha","service":"campanhaService.apuracao","filters":{
            id_empresa: _vm.filters.id_empresa,
            aprovado_pgto: 'S',
            ano_ref: _vm.filters.ano_ref,
            mes_ref: _vm.filters.mes_ref
          },"hasCode":true},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_campanha),callback:function ($$v) {_vm.$set(_vm.filters, "id_campanha", $$v)},expression:"filters.id_campanha"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }