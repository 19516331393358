<template>
  <v-container class="config-container pa-0">
    <v-container class=" pa-0">
      <GastoMensalPorCampanha :query="valueQuery" />
    </v-container>
    <v-container class="container-card">
      <v-card class="d-flex justify-space-around">
        <BaseInformationDashboard
          icon="mdi-domain"
          color="blue darken-4"
          :value="empresas"
          :hiddenValue="getHiddenValue"
          label="Nº EMPRESAS"
        />
        <v-divider :vertical="true" class="divider-card"></v-divider>
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="realizado"
          :hiddenValue="getHiddenValue"
          label="REALIZADO"
        />
        <v-divider :vertical="true" class="divider-card"></v-divider>
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="bonus"
          :hiddenValue="getHiddenValue"
          label="BÔNUS PAGO"
        />
        <v-divider :vertical="true" class="divider-card"></v-divider>
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="media"
          :hiddenValue="getHiddenValue"
          label="MÉDIA BÔNUS"
        />
      </v-card>
      <v-row class="pa-0 mt-4">
        <v-col cols="12" md="12" lg="12">
          <BonusPagoAno :filters="filters" />
        </v-col>
      </v-row>
    </v-container>

    <FilterDashboard @selectedFilters="syncFilters($event)" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import BaseInformationDashboard from "@/components/shared/BaseInformationDashboard";
import FilterDashboard from "./FilterDashboard.vue";
import campanha from "@/services/http/campanhaService";
import currencyMixin from "@/mixins/currencyMixin";

export default {
  name: "Dashboard",
  mixins: [currencyMixin],
  components: {
    GastoMensalPorCampanha: () =>
      import(
        "@/components/campanha/dashboard/campanha/charts/GastoMensalPorCampanha"
      ),
    BonusPagoAno: () =>
      import("@/components/campanha/dashboard/campanha/charts/BonusPagoAno"),
    BaseInformationDashboard,
    FilterDashboard
  },
  data() {
    return {
      filters: {},
      empresas: "",
      bonus: "",
      realizado: "",
      media: ""
    };
  },

  computed: {
    ...mapGetters({
      getHiddenValue: "sistema/getHiddenValue"
    }),

    valueQuery() {
      return this.filters;
    }
  },

  methods: {
    async fetchResultadoRealizadoEmpresa() {
      let { data } = await campanha()
        .resultadoRealizadoEmpresa()
        .show({
          per_page: -1,
          ...this.filters
        });
      this.empresas = `${
        data.data.filter(item => item.realizado != "0").length
      }`;
    },
    async fetchResultadoRealizadoCampanha() {
      let acumulatorBonus = 0;
      let acumulatorRealizado = 0;
      let {
        data: { data }
      } = await campanha()
        .resultadoRealizadoCampanha()
        .show({
          per_page: -1,
          ...this.filters
        });
      data.map(item => {
        acumulatorBonus += parseFloat(item.resultado) || 0;
        acumulatorRealizado += parseFloat(item.realizado) || 0;
      });
      this.getData(data, acumulatorBonus, acumulatorRealizado);
    },

    getData(array, acumulatorBonus, acumulatorRealizado) {
      this.realizado = this.formatValue(acumulatorRealizado).toString();
      this.bonus = this.formatValue(acumulatorBonus).toString();
      this.media = this.formatValue(acumulatorBonus / array.length).toString();
    },

    formatValue(value) {
      return value ? currencyMixin.filters.BrazilianCurrency(value) : 0;
    },

    async syncFilters(filter) {
      this.filters = Object.assign({}, filter);
      await this.fetchResultadoRealizadoCampanha();
      await this.fetchResultadoRealizadoEmpresa();
    }
  },
  async mounted() {
    const date = new Date().toISOString().substr(0, 7);
    const [year, month] = date.split("-");
    const filter = {
      ano_ref: parseInt(year),
      mes_ref: parseInt(month)
    };
    this.syncFilters(filter);
  }
};
</script>

<style scoped>
.container-chart {
  margin-top: -90px;
}

.container-card {
  margin-top: -60px;
  padding: 12px 30px;
}

.config-container {
  background-color: #f5f5f5;
}

.divider-card {
  margin: 30px 0;
  border: 1px solid #e4e4e4;
}
</style>
