<template>
  <v-container class="container-card pa-0">
    <v-tabs v-model="tab" class="pa-2">
      <v-tab>
        Empresa
      </v-tab>
      <v-tab>
        Campanha
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <DashboardEmpresa />
      </v-tab-item>
      <v-tab-item>
        <DashboardCampanha />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import DashboardEmpresa from "@/components/campanha/dashboard/empresa/DashboardEmpresa";
import DashboardCampanha from "@/components/campanha/dashboard/campanha/DashboardCampanha";

export default {
  name: "Dashboard",
  components: {
    DashboardEmpresa,
    DashboardCampanha
  },

  data() {
    return {
      tab: null
    };
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Dashboard Campanha");
  }
};
</script>

<style scoped>
.v-tab--active {
  background-color: rgba(255, 255, 255, 0.05) !important;
  color: #fff !important;
}
.v-tabs-slider {
  background-color: #fff !important;
  height: 100%;
  width: 100%;
}
.v-tab {
  color: #fff !important;
  font-weight: 400;
}
</style>
