<template>
  <div class="d-flex flex-column justify-center align-center pa-8">
    <div>
      <v-icon large class="mb-4" :color="color">{{ icon }}</v-icon>
      <v-icon large class="mb-4" :color="color2">{{ icon2 }}</v-icon>
    </div>
    <span class="mb-2 text-h4" v-if="hiddenValue">{{ value }}</span>
    <span class="mb-2 space-value" v-else></span>
    <span class="style-label-card">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String
    },
    color: {
      type: String
    },
    icon2: {
      type: String
    },
    color2: {
      type: String
    },
    value: {
      type: String
    },
    label: {
      type: String
    },
    hiddenValue: {
      type: Boolean
    }
  }
};
</script>

<style scoped>
.style-label-card {
  color: #757575;
  font-weight: 400;
}

.space-value {
  background-color: #e4e4e4;
  width: 240px;
  height: 40px;
}
</style>
